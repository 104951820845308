<template>
  <div
    class="col-span-3 grid grid-cols-subgrid justify-between rounded-md border border-border-secondary p-2 text-sm"
    ref="root"
    :class="isActive ? 'ring' : ''"
  >
    <div>
      <slot name="name" />
    </div>
    <div class="flex-initial">
      <slot name="type" />
    </div>
    <div class="flex flex-initial flex-col items-end">
      <i
        class="fas fa-check-circle"
        :class="isProcessed ? 'text-teal-600' : 'text-gray-300'"
        type="primary"
      />

      <p class="text-sm text-text-quaternary">
        {{ timeAsTextFilter(time) }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { timeAsTextFilter } from "@/filters";

defineProps<{
  isActive: boolean;
  isProcessed: boolean;
  time: Temporal.PlainDateTime;
}>();
</script>
