<template>
  <flux-card class="mb-5" shadow="never">
    <div class="mb-4">Reeds gebruikte behandelingen</div>
    <flux-short-form>
      <flux-short-form-item label="Aantal" span="col-span-6">
        <flux-input
          v-model:modelValue="patientTreatmentCount.amount"
          :maxLength="2"
          :onlyIntegerInput="true"
        />
      </flux-short-form-item>
      <flux-short-form-item label="Jaar" span="col-span-6">
        <flux-input v-model:modelValue="patientTreatmentCount.reference_year" />
      </flux-short-form-item>
    </flux-short-form>
  </flux-card>
</template>

<script lang="ts" setup>
defineProps<{
  patientTreatmentCount: {
    amount: string;
    reference_year: string;
  };
}>();
</script>
